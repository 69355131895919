import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
import { v1 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Container } from '../../../components/container';
import { advantagesData } from '../../../mocks/advantages';
import s from './advantages.module.scss';
export const advantagesRef = { current: null };
export const Advantages = () => {
    const { t } = useTranslation();
    return (_jsx("section", { className: s.advantages, id: "advantages", ref: advantagesRef, children: _jsxs(Container, { children: [_jsx("h3", { className: s.advantagesTitle, children: t('advantages.title') }), _jsx("div", { className: s.powerFlex, children: advantagesData.map(el => (_jsxs("div", { className: s.powerItem, children: [_jsx("div", { className: s.powerItemPic, children: el.icon }), _jsxs("div", { className: s.powerItemTop, children: [_jsx("div", { className: s.powerItemTitle, children: t(`${el.title}`) }), _jsx("div", { className: s.powerItemDescription, children: t(`${el.description}`) })] })] }, v1()))) })] }) }));
};
