import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container } from '../../components/container';
import Decor from '../../assets/contacts/decor.webp';
import Message from '../../assets/contacts/message.webp';
import { SvgSelector } from '../../assets/svg-selector';
import s from './contacts.module.scss';
export const contactsRef = { current: null };
export const Contacts = () => {
    const { t } = useTranslation();
    return (_jsx("div", { className: s.inner, ref: contactsRef, children: _jsxs(Container, { className: s.innerContainer, children: [_jsxs("div", { className: s.innerRow, children: [_jsxs("div", { className: s.innerLeft, children: [_jsx("h5", { className: s.innerTitle, children: t('contacts.title') }), _jsx("p", { className: s.innerDescription, children: t('contacts.subtitle') }), _jsxs("div", { className: s.innerLinks, children: [_jsxs(Link, { to: "https://t.me/delab", target: "_blank", "aria-label": "telegram link", className: s.innerLink, children: [_jsxs("div", { children: [_jsx(SvgSelector, { id: "telegram" }), " Telegram"] }), _jsx(SvgSelector, { id: "link-gray" })] }), _jsxs(Link, { to: "https://x.com/delabteam", target: "_blank", "aria-label": "twitter link", className: s.innerLink, children: [_jsxs("div", { children: [_jsx(SvgSelector, { id: "twitter" }), " Twitter"] }), _jsx(SvgSelector, { id: "link-gray" })] }), _jsxs(Link, { to: "https://github.com/delab-team", target: "_blank", "aria-label": "github link", className: s.innerLink, children: [_jsxs("div", { children: [_jsx(SvgSelector, { id: "github" }), " Github"] }), _jsx(SvgSelector, { id: "link-gray" })] }), _jsxs(Link, { className: `${s.innerLinkMail} ${s.innerLink}`, "aria-label": "mail link", to: "mailto:delab.ton@gmail.com", children: [_jsxs("div", { children: [_jsx(SvgSelector, { id: "mail" }), " Mail"] }), _jsxs("div", { children: [_jsx("span", { children: "delab.ton@gmail.com" }), _jsx(SvgSelector, { id: "chevron-right" })] })] })] })] }), _jsx("div", { className: s.innerRight, children: _jsx("img", { src: Message, alt: "message" }) })] }), _jsx("img", { src: Decor, className: s.innerDecor, alt: "decor" })] }) }));
};
