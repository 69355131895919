import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
import First from '../assets/advantages/first.svg';
import Second from '../assets/advantages/second.svg';
import Third from '../assets/advantages/third.svg';
import Fourth from '../assets/advantages/fourth.svg';
export const advantagesData = [
    {
        icon: _jsx(First, {}),
        title: 'advantages.item-1.title',
        description: 'advantages.item-1.description'
    },
    {
        icon: _jsx(Second, {}),
        title: 'advantages.item-2.title',
        description: 'advantages.item-2.description'
    },
    {
        icon: _jsx(Third, {}),
        title: 'advantages.item-3.title',
        description: 'advantages.item-3.description'
    },
    {
        icon: _jsx(Fourth, {}),
        title: 'advantages.item-4.title',
        description: 'advantages.item-4.description'
    }
];
