import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Container } from '../../../components/container';
import Decor from '../../../assets/hero/decor.webp';
import HeroImg from '../../../assets/hero/hero-img.webp';
import s from './hero.module.scss';
export const Hero = () => {
    const { t } = useTranslation();
    return _jsxs("div", { className: s.hero, children: [_jsxs(Container, { children: [_jsxs("h1", { className: s.heroTitle, children: [t('hero.title-1'), _jsx("span", { children: t('hero.title-2') })] }), _jsx("p", { className: s.heroDescription, children: t('hero.description') }), _jsx("img", { className: s.heroDecorHero, src: HeroImg, alt: "hero" })] }), _jsx("img", { className: s.heroDecorImg, src: Decor, alt: "decor" })] });
};
